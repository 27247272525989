class RBACService {
  isAllowed(abilities: [], abilityName: string) {
    if (Array.isArray(abilityName)) {
      return abilities.some((ability: any) =>
        abilityName.includes(ability.name)
      );
    }
    return abilities.some((ability: any) => ability.name === abilityName);
  }
}

export default new RBACService();
