import {
	GET_DASHBOARD_ORDER,
	START_DASHBOARD_LOADING,
} from "../../constants/ActionTypes";
import { IDashboardReducer } from "../Interface/DashboardReducerInterface";
import { IAction } from "../Interface/ActionInterface";

let type = 'support';

if (window.location.hostname.split('.')[0] === 'partners') {
	type = 'partner';
} else {
	const prefix = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');

	if (['admin'].includes(prefix)) {
		type = prefix;
	}
}

// to test partners dashboard on development, you need to add to the url (prefix or suffix): /partner
if (process.env.NODE_ENV === 'development' && /partner/.test(window.location.href)) {
	type = 'partner';
}
if (type == 'support') localStorage.removeItem('servicepoint');

const initialState: IDashboardReducer = {
	orders: [],
	loading: false,
	type,
};

export default (state: IDashboardReducer = initialState, { type, payload }: IAction) => {
	switch (type) {
		case GET_DASHBOARD_ORDER:
			return {
				...state,
				orders: payload,
				loading: false
			};
		case START_DASHBOARD_LOADING:
			return {
				...state,
				loading: true
			};
		default:
			return state;
	}
};
