import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedDepartment,
  setDepartmentStartAsync,
  setPartnerDepartmentStartAsync,
} from "actions/Actions/DepartmentActions";
import {
  // IDepartmentDropdownListProps,
  // IRootDepartmentDropdownListState,
  IDepartmentList,
} from "./Interface/IndexInterface";
import { Badge } from "reactstrap";
import { useLocation } from "react-router";

const DepartmentDropdownList = ({ handleRequestClose }) => {
  const dispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const department: any = useSelector((state: any) => state.department);
  const dashboardType: any = useSelector(
    (state: any) => state.dashboardType?.type
  );

  useEffect(() => {
    console.log("dashboardTypedashboardType==", dashboardType);
    if (dashboardType === "partner") {
      dispatch(setPartnerDepartmentStartAsync());
    } else {
      dispatch(setDepartmentStartAsync(query.get("department")));
    }
  }, []);

  const handleListItemClick = (depart: IDepartmentList) => {
    if (
      !department ||
      !department.selectedDepartment ||
      depart.slug !== department.selectedDepartment.slug
    ) {
      dispatch(addSelectedDepartment(depart));
    }
    handleRequestClose();
  };

  return (
    <List>
      {department &&
        department.departmentsList
          .sort((a, b) => b.open_orders - a.open_orders)
          .map((department: any) => (
            <ListItem
              button
              onClick={() => handleListItemClick(department)}
              key={department.id}
              className="department-list"
            >
              {/* <ListItemAvatar >
            <Avatar variant="square" alt={department.name} src={department.image.small} />
          </ListItemAvatar> */}
              <ListItemText
                className="d-flex justify-content-between"
                disableTypography
              >
                <span>{department.name.replace(/ServiceRight |Vandaag /g, "")}</span>
                <span>
                  {department.open_orders ? (
                    <Badge color="danger" pill>
                      {department.open_orders}
                    </Badge>
                  ) : null}
                </span>
              </ListItemText>
            </ListItem>
          ))}
    </List>
  );
};

DepartmentDropdownList.propTypes = {
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

export default DepartmentDropdownList;
