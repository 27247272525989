import React, { useState, useEffect } from "react";
import IntlMessages from "../../util/IntlMessages";
import axios from "../../util/Api";
import PaginationTable from "common/PaginationTable";
import { useIntl } from "react-intl";
import { readableDateTimeLocale } from "../../util/helper";
import { useHistory } from "react-router";
// import CardMenuEmail from "components/dashboard/Common/CardMenuEmail/CardMenuEmail";

interface IState {
  emailList: any;
  metaData: any;
  isLoading: boolean;
  total: string;
  tableLoading: boolean;
  search: string;
  error: string;
  addData: boolean;
  id: number | undefined;
  x: number;
  y: number;
  menuState: boolean;
}

const EmailsList: React.FC<any> = ({
  apiUrl,
  orderId,
  handleRowClick,
  isSystemEmail = false,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const [state, setState] = useState<IState>({
    emailList: [],
    metaData: { has_more_pages: false },
    isLoading: false,
    total: "",
    tableLoading: false,
    id: undefined,
    search: "",
    error: "",
    addData: false,
    x: 0,
    y: 0,
    menuState: false,
  });

  const RecipientCell = ({ recipients }) => {
    const obj = recipients;

    const toTitle =
      obj?.to && !Array.isArray(obj?.to)
        ? `${obj?.to.name} <${obj.to.email}>`
        : obj?.to
        ? `${obj?.to[0].name} <${obj.to[0].email}>`
        : "-";
    return (
      <ul className="table_recipient_cell m-0 p-0">
        <li>
          <span>
            <IntlMessages id="toTitle" />:
          </span>{" "}
          {toTitle}{" "}
        </li>
        {obj?.bcc && !Array.isArray(obj?.bcc) ? (
          <li>
            <span>
              <IntlMessages id="bccEmail" />:
            </span>
            {`${obj?.bcc.name} <${obj?.bcc.email}>`}
          </li>
        ) : obj?.bcc && obj?.bcc.length ? (
          <li>
            <span>
              <IntlMessages id="bccEmail" />:
            </span>
            {obj?.bcc.map((bccObj, i) => {
              return `${bccObj?.name} <${bccObj?.email}>`;
            })}
          </li>
        ) : null}
        {obj?.cc && !Array.isArray(obj?.cc) ? (
          <li>
            <span>cc:</span>
            {`${obj?.cc.name} <${obj?.cc.email}>`}
          </li>
        ) : obj?.cc && obj?.cc?.length ? (
          <li>
            <span>cc:</span>
            {obj?.cc.map((bccObj, i) => {
              return `${bccObj?.name} <${bccObj?.email}>`;
            })}
          </li>
        ) : null}
      </ul>
    );
  };

  const dateTimeCell = (datetime) => {
    const localDateTimeFormat = intl.formatMessage({
      id: "localeDateTime",
      defaultMessage: "DD-MM-YYYY hh:mm:ss",
    });
    const formattedOrderDate =
      datetime && readableDateTimeLocale(datetime, localDateTimeFormat);
    return formattedOrderDate;
  };

  const createdDate = (obj) => {
    return dateTimeCell(obj.created_at);
  };

  const senderCell = (obj) => {
    return `${obj.sender.name} <${obj.sender.email}>`;
  };

  const [callRecordColumns] = useState([
    {
      name: "subject",
      key: "subject",
    },
    {
      name: "sender",
      key: "sender",
      render: (data) => senderCell(data),
    },
    {
      name: "recipients",
      key: "recipients",
      render: (data) => RecipientCell(data),
    },
    {
      name: "open_count",
      key: "opens_counts",
    },
    {
      name: "driver",
      key: "driver",
    },
    {
      name: "status",
      key: "status",
    },
    {
      name: "created_date",
      key: "created_at",
      render: (data) => createdDate(data),
    },
  ]);

  const fetchData = ({ page, limit }) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const params = { page: page + 1, limit };
    axios.get(apiUrl, { params })
      .then((response: any) => {
        setState((prevState) => ({
          ...prevState,
          emailList: prevState.addData
            ? [...prevState.emailList, ...response.data.data]
            : response.data.data,
          metaData: response.data.meta,
          tableLoading: false,
          isLoading: false,
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          error: error.message,
          tableLoading: false,
        }));
      });
  };

  const handleContextMenu = (event, data) => {
    event.preventDefault();
    if (state.menuState) {
      setState((prevState) => ({
        ...prevState,
        x: 0,
        y: 0,
        menuState: false,
        id: undefined,
      }));
    } else {
      let xCordinate = event.clientX;
      let yCordinate = event.clientY;
      setState((prevState) => ({
        ...prevState,
        menuState: true,
        x: xCordinate,
        y: yCordinate,
        id: data.id,
      }));
    }
  };

  /*const handleRequestClose = (event, path) => {
    event.stopPropagation();
    if (path !== "backdropClick") {
      history.push(path);
    }
    setState((prevState) => ({
      ...prevState,
      x: 0,
      y: 0,
      menuState: false,
      id: undefined,
    }));
  };

  const closeContextMenu = () => {
    setState((prevState) => ({
      ...prevState,
      menuState: false,
      x: 0,
      y: 0,
    }));
  };*/

  useEffect(() => {
    fetchData({ page: 0, limit: 25 });
  }, []);

  return (
    <>
      <PaginationTable
        meta={state.metaData}
        dataList={state.emailList}
        columns={callRecordColumns}
        loading={state.isLoading}
        onChange={fetchData}
        onRowClick={handleRowClick}
        contextMenuHandler={{
          handleOnRightClick: handleContextMenu,
        }}
        isContextMenu={true}
        error={state.error}
      />
      {/* <CardMenuEmail
        style={{
          x: state?.x,
          y: state?.y,
        }}
        menuState={state?.menuState}
        orderId={orderId}
        emailId={state?.id}
        isSystemEmail={isSystemEmail}
        handleRequestClose={handleRequestClose}
        handleClose={closeContextMenu}
      /> */}
    </>
  );
};

export default EmailsList;
