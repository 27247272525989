const getQueryParamFromLocation = ({ location, param }: any) => {
  const split = location.toString().split('?');
  if (split.length < 2) {
    return null;
  }
  const pairs = split[1].split('&');
  for (const pair of pairs) {
    const [key, value] = pair.split('=');
    if (key === param) {
      return decodeURIComponent(value || '');
    }
  }
  return null;
}

export const getCodeFromLocation = ({ location }: any) => {
  return getQueryParamFromLocation({ location, param: 'code' });
}

export const getDepartmentFromLocation = ({ location }: any) => {
  return getQueryParamFromLocation({ location, param: 'department' });
}

export const getLoginSignatureFromLocation = ({ location }: any) => {
  return getQueryParamFromLocation({ location, param: 'login-signature' });
}
