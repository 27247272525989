import React from 'react'
import {create} from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/styles';

interface IRTL {
  children:React.ReactNode
}

// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

function RTL(props:IRTL) {
  return (
    <StylesProvider jss={jss}>
      {props.children}
    </StylesProvider>
  );
}
export default RTL;
