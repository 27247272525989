import {Redirect} from "react-router-dom";
import React from "react";

import asyncComponent from '../util/asyncComponent';
import { 
	ACCESS_SUBSCRIPTION_SYSTEM,
	ANALYTICS_SERVICE_BOOKING_SERVICE_ASSIGNED_ORDERS_QUERY,
	ANALYTICS_SERVICE_BOOKING_SERVICE_CANCELED_ORDERS_QUERY,
	ANALYTICS_SERVICE_BOOKING_SERVICE_OPEN_QUOTES_QUERY,
	BOOKING_SERVICE_SUPPORT_CREATE_ORDERS,
	BOOKING_SERVICE_SUPPORT_ORDER_ACCESS,
	FEEDBACK_SERVICE_INDEX_FEEDBACK,
	LIST_CALL_QUEUES,
	NOTIFICATION_SERVICE_SHOW_SYSTEM_NOTIFICATIONS, PHONE_SERVICE_CALL_RECORDINGS_ACCESS, SERVICE_POINTS_MAPS_ACCESS,
	SERVICE_POINTS_OVERVIEW_ACCESS, SERVICE_POINTS_SERVICE_CREATE_SERVICE_POINT, USEFUL_LINKS 
} from "rbac/abilities.constants";

// @ts-ignore
export default {
	admin: {
		routes: [
			{
				component: asyncComponent(() => import('./../app/routes/EmployeesPage')),
				path: '/admin/employees',
				id: 'admin.employees',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
				hideChild: true,
				child: [
					{
						component: asyncComponent(() => import('./../components/EmployeesTable/EmployeeAccountOverview')),
						path: '/admin/employees/:id/',
						id: 'employees.overview'
					}
				]
			},
			{
				component: asyncComponent(() => import('./../app/routes/RolesPage')),
				path: '/admin/roles',
				id: 'admin.roles',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
				hideChild: true,
				child: [
					{
						component: asyncComponent(() => import('./../app/routes/RolesPage')),
						path: '/admin/roles/:id',
						id: 'admin.roles',
					}
				]
			},
			{
				component: asyncComponent(() => import('../app/routes/Permission')),
				path: '/admin/permission',
				id: 'admin.permission',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
			},
			{
				component: asyncComponent(() => import('../app/routes/ServicesPage')),
				path: '/admin/services',
				id: 'sidebar.services',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
				hideChild: true,
				child: [
					{
						component: asyncComponent(() => import('../app/routes/ServicesPage')),
						path: '/admin/services/create',
					},
					{
						component: asyncComponent(() => import('../app/routes/ServicesPage')),
						path: '/admin/services/:id',
					},
				]
			},
			{
				component: asyncComponent(() => import('../app/routes/Plans')),
				path: '/admin/plans',
				id: 'admin.plans',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
			},
		],
	},

	/**
	 * Marketing routes
	 */
	marketing: {
		routes: [
			{
				component: asyncComponent(() => import('../app/routes/MarketingPage')),
				path: '/marketing',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/MarketingPage')),
				path: '/marketing/campaigns',
				id: 'Campaigns',
				hideChild: true,
				child: [
					{
						component: asyncComponent(() => import('../app/routes/MarketingPage')),
						path: '/marketing/campaigns/:id'
					},
					{
						component: asyncComponent(() => import('../app/routes/MarketingPage')),
						path: '/marketing/campaigns/:id/opens'
					},
					{
						component: asyncComponent(() => import('../app/routes/MarketingPage')),
						path: '/marketing/campaigns/:id/outbox'
					},
					{
						component: asyncComponent(() => import('../app/routes/MarketingPage')),
						path: '/marketing/campaigns/:id/unsubscribe'
					},
				]
			},
			{
				component: asyncComponent(() => import('../app/routes/MarketingPage')),
				path: '/marketing/email-template',
				id: 'Email-template',
				exact: true
			},
			{
				component: asyncComponent(() => import('./../app/routes/MarketingPage')),
				path: '/marketing/lists',
				id: 'Lists',
				exact: true
			},
			{
				component: asyncComponent(() => import('./../app/routes/MarketingPage')),
				path: '/marketing/templates',
				id: 'Templates',
				exact: true
			}
		],
	},

	//account type as a key.
	partner: {
		routes: [
			{
				render: () => (<Redirect to="/dashboard"/>),
				path: '/',
				exact: true,
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/dashboard',
				id: 'sidebar.dashboard',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
				exact: true,
				hideChild: true,
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/orders',
				id: 'sidebar.orders',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
				child:
					[
						{
							component: asyncComponent(() => import('../app/routes/PartnerPage')),
							id: 'sidebar.orders.processing',
							path: '/orders/processing'
						},
						{
							component: asyncComponent(() => import('../app/routes/PartnerPage')),
							id: 'sidebar.orders.scheduled',
							path: '/orders/scheduled'
						},
						{
							component: asyncComponent(() => import('../app/routes/PartnerPage')),
							id: 'sidebar.orders.completed',
							path: '/orders/completed'
						},
						{
							component: asyncComponent(() => import('../app/routes/PartnerPage')),
							id: 'sidebar.orders.awaiting_completion',
							path: '/orders/awaiting_completion'
						},
						{
							component: asyncComponent(() => import('../app/routes/PartnerPage')),
							id: 'sidebar.orders.cancelled',
							path: '/orders/cancelled'
						},
					]
			},

			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/orders/:id',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/employees',
				id: 'partner.employees',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/subscriptions',
				id: 'partner.subscriptions',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
        child:
          [
            {
              component: asyncComponent(() => import('../app/routes/PartnerPage')),
              id: 'partner.mySubscriptions',
              path: '/subscriptions/my-subscriptions'
            },
            {
              component: asyncComponent(() => import('../app/routes/PartnerPage')),
              id: 'partner.pricing',
              path: '/subscriptions/pricing'
            },
          ]
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/services',
				id: 'partnerUpcomingAssignment.services',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/subscriptions/my-subscriptions/:id',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/invoices',
				id: 'partner.invoices',
				icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
        child:
          [
            {
              component: asyncComponent(() => import('../app/routes/PartnerPage')),
              id: 'partner.invoices',
              path: '/invoices'
            },
            {
              component: asyncComponent(() => import('../app/routes/PartnerPage')),
              id: 'partner.payouts',
              path: '/invoices/payouts'
            },
          ]
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/invoices/:id',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/invoices/:id/cancel',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/invoices/:id/success',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/reviews',
				id: 'sidebar.reviews',
				icon: 'zmdi zmdi-hc-fw zmdi zmdi-comment-text-alt zmdi-hc-fw',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/settings',
				id: 'partner.settings',
				icon: 'zmdi zmdi-widgets zmdi-hc-fw',
				child:
					[
						{
							component: asyncComponent(() => import('../app/routes/PartnerPage')),
							id: 'partner.settingsSubmenu',
							path: '/settings'
						},
						{
							component: asyncComponent(() => import('../app/routes/PartnerPage')),
							id: 'partner.payoutMethods',
							path: '/settings/payout-methods'
						},
						// {
						// 	component: asyncComponent(() => import('../app/routes/PartnerPage')),
						// 	id: 'partner.employeeSettingsSubmenu',
						// 	path: '/settings/employees'
						// },
					]
			},

			/**  "partner.settingsSubmenu": "Partner Settings",
  "partner.employeeSettingsSubmenu": "Employee Settings"
			 *                 id: 'Settings',
                icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
                exact: true
            },
            {
                component: asyncComponent(() => import('../app/routes/PartnerPage')),
                path: '/employees',
                id: 'Employees',
                icon: 'zmdi zmdi-view-dashboard zmdi-hc-fw',
                exact: true
			 */
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/error/403',
				exact: true
			},
			{
				component: asyncComponent(() => import('../app/routes/PartnerPage')),
				path: '/settings/error/404',
				exact: true
			}
		],
	},

	customer: {
		routes: [
			{
				component: asyncComponent(() => import('../app/routes/CustomerPage/index')),
				path: '/customer',
				id: 'customer.main'
			},
			{
				component: asyncComponent(() => import('../app/routes/CustomerPage/index')),
				path: '/customer/orders',
				id: 'customer.main'
			},
			{
				component: asyncComponent(() => import('../app/routes/CustomerPage/index')),
				path: '/customer/settings',
				id: 'customer.main'
			}
		],
	},

	support: {
		routes: [
			// {
			// 	render: () => (<Redirect to="/support/orders"/>),
			// 	path: '/support',
			// 	exact: true,
			// },
			// {
			// 	component: asyncComponent(() => import('./../app/routes/Dashboard')),
			// 	path: '/support/dashboard',
			// 	id: 'sidebar.dashboard',
			// 	icon: 'zmdi zmdi-widgets zmdi-hc-fw',
			// },
			{
				
				component: asyncComponent(() => import('./../app/routes/OrdersStatus')),
				path: '/support/orders',
				id: 'sidebar.orders',
				dataCy: 'menuOrders',
				icon: 'zmdi zmdi-hc-fw zmdi zmdi-case zmdi-hc-fw',
				permission: [BOOKING_SERVICE_SUPPORT_CREATE_ORDERS, BOOKING_SERVICE_SUPPORT_ORDER_ACCESS],
				child: [
					{
						path: '/support/orders/create',
						id: 'sidebar.order.create',
						permission: BOOKING_SERVICE_SUPPORT_CREATE_ORDERS
					},
					{
						path: '/support/orders',
						exact: true,
						id: 'sidebar.order.overview',
						permission: BOOKING_SERVICE_SUPPORT_ORDER_ACCESS,
					}
				]
			},
			{
				component: asyncComponent(() => import('./../app/routes/CompaniesPage')),
				icon: 'zmdi zmdi-hc-fw zmdi zmdi-local-store zmdi-hc-fw',
				path: '/support/companies',
				id: 'sidebar.companies',
				permission: [SERVICE_POINTS_OVERVIEW_ACCESS, SERVICE_POINTS_MAPS_ACCESS, SERVICE_POINTS_SERVICE_CREATE_SERVICE_POINT],
				child: [
					{
						path: '/support/companies',
						id: 'sidebar.overview',
						exact:true,
						hideChild: true,
						permission: SERVICE_POINTS_OVERVIEW_ACCESS,
						child: [
							{
								path: '/support/companies/error/404',
							},
							{
								path: '/support/companies/:id/edit',
							},
							{
								path: '/support/companies/:id/',
							},
							{
								path: '/support/companies/:id/:activeTab/',
							},
							{
								path: '/support/companies/:id/:activeTab/:activeTabId',
							},
							{
								path: '/support/companies/:id/:activeTab/:activeTabId/activeTabEdit',
							},
						]
					},
					// {
					// 	path: '/support/companies/maps',
					// 	id: 'sidebar.maps',
					// 	permission: SERVICE_POINTS_MAPS_ACCESS,
					// },
					{
						path: '/support/companies/create',
						id: 'sidebar.companies.create',
						permission: SERVICE_POINTS_SERVICE_CREATE_SERVICE_POINT,
					}
				]
			},
			/*{
				component: asyncComponent(() => import('./../app/routes/CallQueuePage')),
				path: '/support/call-queues',
				id: 'sidebar.callQueue',
				permission: LIST_CALL_QUEUES,
				icon: 'zmdi zmdi-hc-fw zmdi zmdi-view-list-alt zmdi-hc-fw',
				hideChild: true,
				child: [
					{
						component: asyncComponent(() => import('./../app/routes/TablePage')),
						path: '/support/call-queues/:id',
						id: 'call-queues.tablePage'
					},
					{
						component: asyncComponent(() => import('./../components/CallQueueList')),
						path: '/support/call-queues/:id/process',
						id: 'call-queues.CallQueueList'
					},
					{
						component: asyncComponent(() => import('./../components/CallQueueListNew')),
						path: '/support/call-queues/:id/process-queue',
						id: 'call-queues.CallQueueList'
					},
					{
						component: asyncComponent(() => import('./../components/CallQueueListNew/SingleEntry')),
						path: '/support/call-queues/:id/process-queue/:entryId',
						id: 'call-queues.CallQueueList'
					},
					{
						component: asyncComponent(() => import('./../app/routes/CallQueuePage')),
						path: '/support/call-queues/error/403',
					}, {
						component: asyncComponent(() => import('./../app/routes/CallQueuePage')),
						path: '/support/call-queues/:id/edit',
					}
				]
			},*/
			{
				component: asyncComponent(() => import('../app/routes/Emails')),
				path: '/support/emails',
				id: 'sidebar.emails',
				permission: [NOTIFICATION_SERVICE_SHOW_SYSTEM_NOTIFICATIONS],
				icon: 'zmdi zmdi-email zmdi-hc-fw',
				child: [
					{
						path: '/support/emails',
						id: 'sidebar.emails.system',
						permission: NOTIFICATION_SERVICE_SHOW_SYSTEM_NOTIFICATIONS,
					}
				]
			},
			{
				component: asyncComponent(() => import('../app/routes/Reports')),
				path: '/support/reports',
				id: 'sidebar.reports',
				icon: 'zmdi zmdi-hc-fw zmdi zmdi-comment-alert zmdi-hc-fw',
				permission: [ANALYTICS_SERVICE_BOOKING_SERVICE_OPEN_QUOTES_QUERY, ANALYTICS_SERVICE_BOOKING_SERVICE_CANCELED_ORDERS_QUERY, ANALYTICS_SERVICE_BOOKING_SERVICE_ASSIGNED_ORDERS_QUERY],
				child: [
					{
						path: '/support/reports/open-quotes',
						id: 'sidebar.reports.openQuotes',
						permission: ANALYTICS_SERVICE_BOOKING_SERVICE_OPEN_QUOTES_QUERY,
					},
					{
						path: '/support/reports/canceled-orders',
						id: 'sidebar.reports.canceledOrders',
						permission: ANALYTICS_SERVICE_BOOKING_SERVICE_CANCELED_ORDERS_QUERY,
					},
					{
						path: '/support/reports/assigned-orders',
						id: 'sidebar.reports.assignedOrders',
						permission: ANALYTICS_SERVICE_BOOKING_SERVICE_ASSIGNED_ORDERS_QUERY,
					}
				]
			},
			{
				component: asyncComponent(() => import('../app/routes/Reviews')),
				path: '/support/reviews',
				id: 'sidebar.reviews',
				permission: FEEDBACK_SERVICE_INDEX_FEEDBACK,
				exact: true,
				icon: 'zmdi zmdi-hc-fw zmdi zmdi-comment-text-alt zmdi-hc-fw',
			},
			{
				component: asyncComponent(() => import('../app/routes/UsefulLinksPage')),
				path: '/support/useful-links',
				id: 'sidebar.links',
				// exact: true,
				permission: USEFUL_LINKS,
				icon: 'zmdi zmdi-hc-fw zmdi zmdi-info zmdi-hc-fw',
				hideChild: true,
				child: [
					{
						component: asyncComponent(() => import('../app/routes/UsefulLinksPage')),
						path: '/support/useful-links/haynes-test-page',
						exact: true
						// id: 'demo.haynes',
					},
				]
			},
			{
				component: asyncComponent(() => import('../app/routes/subscriptions')),
				path: '/support/subscriptions',
				id: 'sidebar.subscription',
				permission: ACCESS_SUBSCRIPTION_SYSTEM,
				exact: true,
				icon: 'zmdi-notifications-active',
			}
			
			// {
			// 	component: asyncComponent(() => import('./../app/routes/PhoneSystemPage')),
			// 	path: '/support/phone-system/call-histories',
			// 	id: 'sidebar.phoneSystem',
			// 	icon: 'zmdi zmdi-phone zmdi-hc-fw',
			// },
			// {
			// 	component: asyncComponent(() => import('./../app/routes/EmailPage')),
			// 	id: 'sidebar.contact',
			// 	icon: 'zmdi zmdi-widgets zmdi-hc-fw',
			// 	child:
			// 		[
			// 			{
			// 				id: 'menu.chat',
			// 				path: '/support/chat'
			// 			},
			// 			{
			// 				id: 'menu.email',
			// 				path: '/support/email'
			// 			},
			// 		]
			// },
			// {
			//     path: '/support/statistics',
			//     id: 'sidebar.tatistics',
			//     icon: 'zmdi zmdi-widgets zmdi-hc-fw',
			//     child: [
			//         {
			//             id: 'statistics',
			//             path: '/stats'
			//         },
			//         {
			//             id: 'timeline',
			//             path: '/stats/timeline'
			//         },
			//     ]
			// },
		],
	},

}
