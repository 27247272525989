import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Spinner } from "reactstrap";
import { getEmployees } from "actions/Actions/SoftPhoneActions";
import { useDispatch } from "react-redux";

const EmployeeListModal: React.FC<any> = ({
  showModal,
  toggleModal,
  onEmpSelect,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [empList, setEmpList] = useState([]);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      height: 400,
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
    },
    listItem: {
      alignItems: "center",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const fetchEmployeeList = () => {
      setLoading(true);
      dispatch(
        getEmployees((status, response) => {
          if (status) {
            setEmpList(response.data);
            setLoading(false);
          }
        })
      );
    };
    fetchEmployeeList();
  }, []);

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={toggleModal}
        className="modal-align"
        keyboard={false}
        style={{ maxWidth: "400px", maxHeight: "600px" }}
      >
        <ModalHeader toggle={toggleModal}>
          <IntlMessages id="empList" />
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div className="text-center">
              <Spinner color="primary" className={"spinner"} />
            </div>
          ) : (
            <List className={classes.root} role="list">
              {empList.length > 0 ? (
                empList.map((el: any, i: number) => (
                  <ListItem
                    className={classes.listItem}
                    key={i}
                    button
                    alignItems="center"
                    onClick={(e) => onEmpSelect(e, el.id)}
                  >
                    <ListItemText
                      primary={`${el.first_name} ${el.last_name}`}
                    />
                  </ListItem>
                ))
              ) : (
                <div className="text-center">
                  <IntlMessages id="paginationTable.noData" />
                </div>
              )}
            </List>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default EmployeeListModal;
