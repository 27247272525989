export const ACCESS_SOFT_PHONE = "access-soft-phone";
export const CALL_QUEUE_ENTRIES_ENTRY_EDIT = "call-queue-entries-entry-edit";
export const CALL_QUEUE_ENTRIES_ENTRY_DELETE = "call-queue-entries-entry-delete";
export const CALL_QUEUE_ENTRIES_ENTRY_CREATE = "call-queue-entries-entry-create";
export const CALL_QUEUE_ENTRIES_UNSUBSCRIBE = "call-queue-entries-unsubscribe";
export const CALL_QUEUE_ENTRIES_APPROVE = "call-queue-entries-approve";
export const CALL_QUEUE_ENTRIES_REJECT = "call-queue-entries-reject";
export const CALL_QUEUE_ENTRIES_RESCHEDULE = "call-queue-entries-reschedule";
export const CALL_QUEUE_SERVICE_DELETE_QUEUE = "call-queue-service-delete-queue";
export const CALL_QUEUE_SERVICE_UPDATE_CALL_QUEUES = "call-queue-service-update-call-queues";
export const CALL_QUEUE_SERVICE_UPDATE_QUEUE_TEMPLATE = "call-queue-service-update-queue-template";
export const CALL_QUEUE_SERVICE_UPDATE_QUEUE = "call-queue-service-update-queue";
export const CALL_QUEUE_SERVICE_LOCK_CALL_QUEUE_ENTRY_AGENT = "call-queue-service-lock-call-queue-entry-agent";
export const CALL_QUEUE_SERVICE_LOCK_CALL_QUEUE_ENTRY_YOURSELF = "call-queue-service-lock-call-queue-entry-yourself";
export const CALL_QUEUE_SERVICE_UNLOCK_CALL_QUEUE_ENTRY = "call-queue-service-unlock-call-queue-entry";
export const LIST_CALL_QUEUES = "list-call-queues";
export const SERVICE_POINTS_OVERVIEW_ACCESS = "service-points-overview-access";
export const BOOKING_SERVICE_SUPPORT_ORDER_ACCESS = "booking-service-support-order-access";
export const PHONE_SERVICE_CALL_HISTORIES_INDEX = "asterisk-manager-call-histories-index";
export const PHONE_SERVICE_CALL_RECORDINGS_ACCESS = "phone-service-call-recordings-access";
export const BOOKING_SERVICE_SUPPORT_ORDER_DELETE = "booking-service-support-order-delete";
export const BOOKING_SERVICE_SUPPORT_CREATE_ORDERS = "booking-service-support-create-orders";
export const CALL_QUEUE_ENTRIES_CREATE_COMMENT = "call-queue-service-call-queue-entries-create-comment";
export const BOOKING_SERVICE_SUPPORT_ORDER_CANCEL = "booking-service-support-order-cancel";
export const ADMIN_MANAGE_PERMISSIONS = "admin-manage-permissions";
export const ADMIN_MANAGE_EMPLOYEES = "admin-manage-employees";
export const ADMIN_MANAGE_ROLES_UPDATE = "admin-manage-roles-update";
export const ADMIN_MANAGE_ROLES_CREATE = "admin-manage-roles-create";
export const SERVICE_PRICES_SERVICE_CREATE_SERVICE = "service-prices-service-create-service";
export const CREATE_EMPLOYEES = "create-employees";
export const DELETE_EMPLOYEES = "delete-employees";
export const CALL_QUEUE_SERVICE_ADD_CALL_QUEUES_AGENT = "call-queue-service-add-call-queues-agent";
export const ACCESS_SUBSCRIPTION_SYSTEM = "subscription-service-access-subscription-system";
export const UPDATE_SERVICEPOINT_INFORMATION = "service-points-service-update-servicepoint-information";
export const UPDATE_CALL_QUEUES = "update-call-queues";
export const UPDATE_SERVICEPOINT_HOURS = "service-points-service-update-servicepoint-hours";
export const BOOKING_SERVICE_SUPPORT_ORDER_UPDATE = "booking-service-support-order-update";
export const VIEW_STATISTICS = "view-statistics";
export const SHOW_AGENTS_CREDENTIALS = "asterisk-manager-show-agents-credentials";
export const CREATE_AGENT_CONNECTION_CREDENTIALS = "asterisk-manager-create-agent-connection-credentials";
export const FEEDBACK_SERVICE_APPROVE_FEEDBACK = "feedback-service-approve-feedback";
export const FEEDBACK_SERVICE_DELETE_FEEDBACK = "feedback-service-delete-feedback";
export const FEEDBACK_SERVICE_INDEX_FEEDBACK = "feedback-service-index-feedback";
export const FEEDBACK_SERVICE_UPDATE_FEEDBACK = "feedback-service-update-feedback";
export const DELETE_AGENT_CONNECTION_CREDENTIALS = "asterisk-manager-delete-agent-connection-credentials";
export const BOOKING_SERVICE_EDIT_ORDER_PREFERRED_DATE = "booking-service-edit-order-preferred-date";
export const BOOKING_SERVICE_SUPPORT_ORDER_CREATE_CALLBACK_REQUEST = "booking-service-support-order-create-callback-request";
export const BOOKING_SERVICE_ASSIGN_ORDER_TO_SERVICE_POINT = "booking-service-assign-order-to-service-point";
export const FEEDBACK_SERVICE_RECOVER_REJECTED_FEEDBACK = "feedback-service-recover-rejected-feedback";
export const BOOKING_SERVICE_SUPPORT_SET_CUSTOM_PRICE = "booking-service-support-set-custom-price";
export const BOOKING_SERVICE_PUT_ORDER_ON_HOLD = "booking-service-put-order-on-hold";
export const SERVICE_POINTS_SERVICE_DELETE_SERVICE_POINT = "service-points-service-delete-service-point";
export const BOOKING_SERVICE_FINISH_ORDERS = "booking-service-finish-orders";
export const BOOKING_SERVICE_SUPPORT_CREATE_ORDER_DISPUTE = "booking-service-support-create-order-dispute";
export const SUBSCRIPTION_SERVICE_CREATE_SUBSCRIPTION = "subscription-service-create-subscription";
export const BOOKING_SERVICE_LOCK_ORDER = "booking-service-lock-order";
export const BOOKING_SERVICE_UNLOCK_ORDER = "booking-service-unlock-order";
export const BOOKING_SERVICE_DISSOCIATE_SERVICE_POINT = "booking-service-dissociate-service-point";
export const BOOKING_SERVICE_ALLOW_DELETE_COMPLETED_ORDER = "booking-service-allow-delete-completed-order";
export const BOOKING_SERVICE_CREATE_ORDER_PREFERRED_DATE = "booking-service-create-order-preferred-date";
export const BOOKING_SERVICE_DELETE_ORDER_PREFERRED_DATE = "booking-service-delete-order-preferred-date";
export const BOOKING_SERVICE_FINISH_ORDERS_UPDATE_AMOUNT = "booking-service-finish-orders-update-amount";
export const BOOKING_SERVICE_FINISH_ORDERS_UPDATE_PRICE = "booking-service-finish-orders-update-price";
export const BOOKING_SERVICE_CREATE_ORDER_COMMENT = "booking-service-create-order-comment";
export const BOOKING_SERVICE_DELETE_ORDER_COMMENT = "booking-service-delete-order-comment";
export const BOOKING_SERVICE_UPDATE_ORDER_COMMENT = "booking-service-update-order-comment";
export const FEEDBACK_SERVICE_ALLOW_APPROVE_BAD_FEEDBACKS = "feedback-service-allow-approve-bad-feedbacks";
export const SERVICE_POINTS_SERVICE_CREATE_SERVICE_POINT = "service-points-service-create-service-point";
export const SUBSCRIPTION_SERVICE_CANCEL_SUBSCRIPTION = "subscription-service-cancel-subscription";
export const SERVICE_POINT_CREATE_RECOGNITION = "service-points-service-create-recognition";
export const SERVICE_POINT_UPDATE_RECOGNITION = "service-points-service-update-recognition";
export const SERVICE_POINT_DELETE_RECOGNITION = "service-point-service-delete-recognition";
export const SERVICE_POINTS_SERVICE_SEND_RECOGNITION_BACKLINK = "service-points-service-send-recognition-backlink";
export const SUBSCRIPTION_SERVICE_DELETE_SUBSCRIPTION = "subscription-service-delete-subscription";
export const PAYMENT_SERVICE_VEHICLES_ALLOW_FREE_DELIVERY = "payment-service-vehicles-allow-free-delivery";
export const PROPERTIES_SERVICE_WRITE_VEHICLES = "properties-service-write-vehicles";
export const ACCOUNTING_SERVICE_CREATE_INVOICES_PAYMENT_SESSIONS = "accounting-service-create-invoices-payment-sessions";
export const ACCOUNTING_SERVICE_DOWNLOAD_INVOICE = "accounting-service-download-invoice";
export const UPDATE_SERVICEPOINT_SALES_NOTES = "service-points-service-update-servicepoint-sales-notes";
export const SERVICE_POINTS_SERVICE_CREATE_EMPLOYEE = "service-points-service-create-employee";
export const BOOKING_SERVICE_ACCESS_ORDER_REJECTION_INFORMATION = "booking-service-access-order-rejection-information";
export const BOOKING_SERVICE_ORDER_REJECTED_SENSITIVE_DETAILS = "booking-service-order-rejected-sensitive-details";
export const ANALYTICS_SERVICE_BOOKING_SERVICE_OPEN_QUOTES_QUERY = "analytics-service-booking-service-open-quotes-query";
export const ANALYTICS_SERVICE_BOOKING_SERVICE_ASSIGNED_ORDERS_QUERY = "analytics-service-booking-service-assigned-orders-query";
export const ANALYTICS_SERVICE_BOOKING_SERVICE_CANCELED_ORDERS_QUERY = "analytics-service-booking-service-canceled-orders-query";
export const BOOKING_SERVICE_PUT_ORDER_PROCESSING = "booking-service-put-order-processing";
export const BOOKING_SERVICE_FILTER_BASED_ON_LOCKED_BY_AGENT_ID = "booking-service-filter-based-on-locked_by_agent_id";
export const BOOKING_SERVICE_REJECT_ORDER = "booking-service-reject-order";
export const USEFUL_LINKS = "useful-links";
export const SERVICE_POINTS_MAPS_ACCESS = "service-points-maps-access";
export const NOTIFICATION_SERVICE_SHOW_SYSTEM_NOTIFICATIONS = "notification-service-show-system-notifications";
export const NOTIFICATION_SERVICE_RESEND_AVAILABLE_ORDER_EMAIL = "notification-service-resend-available-order-email";
export const SUPPORT_ACCESS_PARTNER_EMPLOYEES_DELETE = "support-access-partner-employees-delete";
export const CALL_QUEUE_SERVICE_DELETE_CALL_QUEUES_AGENT = "call-queue-service-delete-call-queues-agent";
export const ASTERISK_MANAGER_CALL_RECORDINGS_DOWNLOAD = "asterisk-manager-call-recordings-download";
export const NOTIFICATION_SERVICE_RESEND_SYSTEM_NOTIFICATION = "notification-service-resend-system-notification";
export const BOOKING_SERVICE_SEND_ORDER_PAYMENT_LINK = "booking-service-send-order-payment-link";
export const BOOKING_SERVICE_EXTEND_QUOTE_ORDER = "booking-service-extend-quote-order";
