import moment from "moment";
import axios from "./Api";

export const readableDate = (Date: string) => {
  return moment(Date).format("DD-MM-YYYY");
};
export const readableDateTime = (Date: string) => {
  return moment(Date).format("DD-MM-YYYY hh:mm");
};
export const doAPICallWithCallBack = (
  url,
  callBack,
  method = "get",
  data: any = null
) => {
  return () => {
    axios[method](url, data)
      .then((response) => {
        callBack(true, response.data);
      })
      .catch((error) =>
        callBack(
          false,
          error.response ? error.response.data.message : "Something went wrong."
        )
      );
  };
};
export const readableDateTimeLocale = (Date: string, Format: string) => {
  let formattedDate = "-";
  if (Date && Format) {
    formattedDate = moment.utc(Date).format(Format);
  }
  return formattedDate;
};
// sort objects based on keys
export const sortObjectOnKeys = (data: any) => {
  let sortedData = {};
  let sort = Object.keys(data).sort((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });
  sort.forEach((item) => {
    sortedData[item] = data[item];
  });
  return sortedData;
};

export const currencyConventor = (
  amount,
  currencyCode = "EUR",
  locale = "nl"
) => {
  var formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode || "EUR",
  });
  return formatter.format(amount);
};

export const formatPrice = (price: number | string) => {
  if (price === "GRATIS" || price === "FREE") {
    return price;
  }
  if (price && price.toString().length > 2) {
    const priceStr = price.toString();
    return (
      priceStr.slice(0, priceStr.length - 2) +
      "," +
      priceStr.slice(priceStr.length - 2)
    );
  } else if (price) {
    return price;
  }
  return "0,00";
};

export const convertPrice = (price: any) => {
  const parsed = parseFloat(price.replace(/,/g, "."));

  return !isNaN(parsed) ? parsed * 100 : 0;
};
