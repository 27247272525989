import React from "react";
import IntlMessages from "../../util/IntlMessages";
import { useHistory } from "react-router";
import EmailsList from "common/EmailList";

const EmailLists: React.FC<any> = (props) => {
  const history = useHistory();

  const redirectToEmailDetailPage = (emailId) => {
    const emailDetailRedirectURL = `/support/customers/${props.id}/emails/${emailId}`;
    history.replace(emailDetailRedirectURL);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3 className="mt-2">
          <IntlMessages id="orderDetailViewTable.emailList" />
        </h3>
      </div>
      <EmailsList
        orderId={props.id}
        apiUrl={`/customers/${props.id}/emails`}
        handleRowClick={redirectToEmailDetailPage}
      />
    </>
  );
};

export default EmailLists;
